<template>
  <Form :items="items"/>
</template>

<script>
import Form from '../Form/Step3.vue';
export default {
  components: {
    Form
  },
  props: {
    items: {
      type: Boolean
    }
  }
};
</script>