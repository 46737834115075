<template>
  <div class="step-3">
    <div class="p-d-flex checkbox p-mb-3" v-if="canSelectEmployeeSchedule">
      <p class="p-mr-5 font-semibold">{{ i18n.$t('Use employee schedule') }}</p>
      <Checkbox v-model="items.is_schedule_employee" :binary="true" />
    </div>
    <div v-if="!items.is_schedule_employee">
      <div class="flex mt-5">
        <p class="w-1/4 font-semibold">{{ i18n.$t("Service's schedule") }}</p>
      </div>
      <div class="mt-5 mb-5">
        <RDV :weekDayTimes="weekDayTimes" />
        <input type="hidden" name="schedule" :value="items.schedule" />
      </div>
    </div>
  </div>
</template>
<script>
import RDV from '@/components/RDV/RDV.vue';
import { computed, ref } from 'vue';
import useI18n from '@/plugins/useI18n';

export default {
  props: {
    items: {
      type: Object
    },
    scheduleData: {
      type: Object
    }
  },
  components: {
    RDV
  },
  setup(props) {
    const canSelectEmployeeSchedule = ref(true);
    if (!props.items.employee_id || props.items.employee_id.length != 1) {
      canSelectEmployeeSchedule.value = false;
      props.items.is_schedule_employee = false;
    }
    const weekDayTimes = computed(() =>
      props.scheduleData ? props.scheduleData : props.items.schedule
    );
    const { i18n } = useI18n();

    return {
      weekDayTimes,
      i18n,
      canSelectEmployeeSchedule
    };
  }
};
</script>

<style lang="scss" scoped>
>>> .actions {
  margin-top: 3rem !important;
}
.step-3 {
  width: 100%;
}
</style>